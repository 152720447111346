<template>
  <div class="container-xxl">
    <div class="d-flex">
      <div class="flex-grow-0 flex-shrink-0 my-3">
        <ul class="list-unstyled m-0 me-5">
          <li v-for="(tab, tabIndex) in tabs" :key="`key-${tabIndex}`" class="my-2">
            <a
              @click="activeTab = tab.slug"
              :href="`#${tab.slug}`"
              :class="{ 'fw-bold text-primary' : activeTab === tab.slug }"
              class="text-decoration-none"
            >{{ tab.name }}</a>
          </li>
        </ul>
      </div>
      <div class="flex-grow-1 my-3">
        <AdminStats v-if="activeTab === 'stats'"></AdminStats>
        <!-- TODO: DEPRECATED -->
        <SubscriptionRequestList v-if="activeTab === 'subsrequests'"></SubscriptionRequestList>
        <CoachList v-if="activeTab === 'coaches'"></CoachList>
        <AthleteList v-if="activeTab === 'athletes'"></AthleteList>
        <AdminLibraryManager v-if="activeTab === 'library'"></AdminLibraryManager>
        <AdminStorageManager v-if="activeTab === 'storage'"></AdminStorageManager>
        <SyslogList v-if="activeTab === 'syslog'"></SyslogList>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AdminDashboard',
  components: {
    AdminStats: () => import('@/components/admin/AdminStats'),
    // TODO: DEPRECATED
    SubscriptionRequestList: () => import('@/controllers/subscription/request/RequestList'),
    CoachList: () => import('@/controllers/user/coach/CoachList'),
    AthleteList: () => import('@/controllers/user/athlete/AthleteList'),
    AdminLibraryManager: () => import('@/components/admin/AdminLibraryManager'),
    AdminStorageManager: () => import('@/components/admin/AdminStorageManager'),
    SyslogList: () => import('@/controllers/syslog/SyslogList'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
  },
  data() {
    return {
      activeTab: 'stats',
      tabs: [
        {
          slug: 'stats',
          name: 'Stats',
        },
        // TODO: DEPRECATED
        {
          slug: 'subsrequests',
          name: 'Subscription requests',
        },
        {
          slug: 'coaches',
          name: 'Coaches',
        },
        {
          slug: 'athletes',
          name: 'Athletes',
        },
        {
          slug: 'library',
          name: 'Library',
        },
        {
          slug: 'storage',
          name: 'Storage',
        },
        {
          slug: 'syslog',
          name: 'System log',
        },
      ],
    };
  },
  created() {
    const hash = window.location.hash.replace('#', '');
    const tab = this.tabs.find((t) => t.slug === hash);
    if (tab) {
      this.activeTab = tab.slug;
    } else {
      this.activeTab = 'stats';
    }
  },
};
</script>
